import { ChangeEvent, ComponentRef, FC, useCallback, useEffect, useRef, useState } from 'react';
import { ClientForm, SignatureDetails } from '../../models/ClientForm';
import StandardModal from '../shared/modal/variants/StandardModal';
import { Trans, useTranslation } from 'react-i18next';
import useFormTypeText from '../../hooks/useFormTypeText';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { useModal } from '../../contexts/ModalContext';
import { Input } from '../shared/form-control/Input';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import DateUtils from '../../utils/DateUtils';
import signatureStamp from '../../assets/images/signature-stamp.svg';
import MultiTextField from '../shared/form-control/MultiTextField';
import OTPInput from '../shared/form-control/OTPInput';
import InfoIcon from '../shared/icon/InfoIcon';
import Tooltip from '../shared/Tooltip';

type SignatureModalProps = {
  mode: 'validate' | 'approve' | 'acknowledgement';
  clientForm: ClientForm;
  reason: string;
  onReasonChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSign: (signatureDetails: SignatureDetails) => Promise<void>;
  requiresAuth?: boolean;
  signerName?: string;
};

const SignatureModal: FC<SignatureModalProps> = (props) => {
  const { clientForm, reason, onReasonChange, mode, onSign, requiresAuth = true, signerName } = props;
  const { t } = useTranslation('common');
  const formTypeText = useFormTypeText(clientForm.type, t);
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const modal = useModal();

  const [signMode, setSignMode] = useState(true);
  const [userName, setUserName] = useState(currentUser?.fullName || '');
  const [now, setNow] = useState(new Date());
  const nowInterval = useRef<number | null>(null);

  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const otpInputRef = useRef<ComponentRef<typeof OTPInput>>(null);

  useEffect(() => {
    if (modal.open) {
      const interval = (nowInterval.current = window.setInterval(() => {
        setNow(new Date());
      }, 1000));

      return () => {
        window.clearInterval(interval);
        nowInterval.current = null;
      };
    }
  }, [modal.open]);

  useEffect(() => {
    if (!modal.open) {
      setSignMode(true);
      setUserName(signerName || currentUser?.fullName || '');
      setSubmitting(false);
      setOtp('');
      setPassword('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onReasonChange({ target: { value: '' } } as any);
      nowInterval.current && window.clearInterval(nowInterval.current);
    }
  }, [currentUser?.fullName, modal.open, onReasonChange, signerName]);

  const submit = useCallback(
    (pinOverride?: string) => {
      setSubmitting(true);
      onSign({
        signerName: userName,
        password,
        twoFactor: pinOverride || otp,
      })
        .catch(() => {
          setSubmitting(false);
          setOtp('');
          setPassword('');
          otpInputRef.current?.clearOtp();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onSign, otp, password, userName],
  );

  return signMode ? (
    <StandardModal
      title={t(`form-header.modals.${mode}-signing.heading`)}
      cancelButtonTitle={t(`form-header.modals.${mode}-signing.buttons.cancel`)}
      confirmButtonTitle={t(`form-header.modals.${mode}-signing.buttons.sign`)}
      onCancelClick={modal.onClose}
      onConfirmClick={() => (requiresAuth ? setSignMode(false) : submit())}
      confirmDisabled={!userName || (requiresAuth && !reason) || submitting}
      confirmLoading={submitting}
    >
      <Trans
        t={t}
        i18nKey={`form-header.modals.${mode}-signing.subheading`}
        components={{ Bold: <span className="font-medium" /> }}
        values={{ type: formTypeText.toLowerCase(), company: currentClient?.name || clientForm.clientName }}
      />

      <div className="mb-4 mt-8">
        <Input value={userName} onChange={(e) => setUserName(e.target.value)} label={t(`form-header.modals.inputs.full-name`)} />
      </div>

      <div>
        <span className="text-color-3 text-dpm-12">{t(`form-header.modals.${mode}-signing.preview`)}</span>

        <div className="border-gray-3 flex select-none items-center justify-center rounded border p-4">
          <div className="flex flex-col gap-4 text-center">
            <div className="font-signature text-dpm-25">{userName}</div>
            <div className="flex items-center justify-center gap-8">
              <div className="text-dpm-12">{DateUtils.formatDateTime(now, false, false)}</div>
              <div>
                <img src={signatureStamp} className="max-w-[75px]" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <MultiTextField
        value={reason}
        onChange={onReasonChange}
        label={t(`form-header.modals.inputs.${requiresAuth ? 'reasoning' : 'reasoning-public'}`)}
        placeholder={t(`form-header.modals.inputs.${requiresAuth ? 'reasoning' : 'reasoning-public'}`)}
      />
    </StandardModal>
  ) : (
    <StandardModal
      title={t('form-header.modals.authenticate-signing.heading')}
      cancelButtonTitle={t('form-header.modals.authenticate-signing.buttons.cancel')}
      confirmButtonTitle={t('form-header.modals.authenticate-signing.buttons.authenticate')}
      onCancelClick={modal.onClose}
      onConfirmClick={submit}
      confirmDisabled={!password || !otp || submitting}
      confirmLoading={submitting}
    >
      <Trans
        t={t}
        i18nKey="form-header.modals.authenticate-signing.subheading"
        components={{ Bold: <span className="font-medium" /> }}
        values={{ type: formTypeText.toLowerCase(), company: currentClient?.name || clientForm.clientName }}
      />

      <div className="mt-8">
        <Input value={currentUser?.email} readonly label={t('form-header.modals.authenticate-signing.inputs.email')} />
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          label={t('form-header.modals.authenticate-signing.inputs.password')}
          placeholder={t('form-header.modals.authenticate-signing.inputs.password')}
          autoFocus
        />

        <div className="text-dpm-12 mt-6">
          {t('form-header.modals.authenticate-signing.inputs.otp')}{' '}
          <Tooltip text={t('form-header.modals.authenticate-signing.otp-info')}>
            {(tooltip) => (
              <span {...tooltip}>
                <InfoIcon className="h-4 w-4" />
              </span>
            )}
          </Tooltip>
        </div>
        <div className="w-96">
          <OTPInput
            ref={otpInputRef}
            size="small"
            disabled={submitting || !password}
            onComplete={(pin) => {
              setOtp(pin);
              password && submit(pin);
            }}
          />
        </div>
      </div>
    </StandardModal>
  );
};

export default SignatureModal;
